<template>
    <div>
        <v-layout>
            <div class="boxapp">
                <!-- boxapp head -->
                <div class="boxapp-head">
                    <a href="#" @click="$router.go(-1)">
                        <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
                    </a>
                    <h1>Projeto de Voluntariado</h1>
                </div>

                <!-- boxapp body -->
                <div class="box-main">
                    <StepsVolunteering :page-options="pageOptions" :volunteering="project" @goToNextStep="nextPage(step)"/>
                    <div class="section">
                        <!-- section head -->
                        <div class="head">
                            <h5>PASSO 1</h5>
                            <h4>Descrição</h4>
                        </div>
                        <!-- section body -->
                        <div class="wrapper">
                            <v-layout row wrap mw-half>
                                <v-flex xs12>
                                    <InputText
                                        @focus="updateHelpBox"
                                        :helpBoxText="
                                            helpBoxTextEnum.ProjectStep1.Description.FullName
                                        "
                                        ref="fullNameInput"
                                        id="fullNameInput"
                                        v-model="project.fullName"
                                        type="text"
                                        place-holder="Escreva o nome aprovado para este projeto"
                                        textLabel="Nome completo do projeto"
                                        helpLabel="Ex: Abrace uma Causa"
                                        invalidInputMessage="Preencha o nome completo do projeto"
                                        @validationStatusChanged="validateStep"
                                        @input="checkForm()"
                                        :focusOnCreate="true"
                                        labelCharLimit="255"
                                        :required="true"
                                    />
                                </v-flex>
                                <v-flex xs12>
                                    <InputText
                                        @focus="updateHelpBox"
                                        :helpBoxText="
                                            helpBoxTextEnum.ProjectStep1.Description.ShortName
                                        "
                                        ref="shortNameInput"
                                        id="shortNameInput"
                                        v-model="project.shortName"
                                        type="text"
                                        place-holder="Dê um nome curto para este projeto"
                                        textLabel="Nome Resumido do Projeto"
                                        helpLabel="Ex: AUC"
                                        invalidInputMessage="Preencha o nome resumido do projeto"
                                        @validationStatusChanged="validateStep"
                                        @input="checkForm()"
                                        labelCharLimit="30"
                                        :required="true"
                                    />
                                </v-flex>
                                <v-flex xs12>
                                    <InputText
                                        @focus="updateHelpBox"
                                        :helpBoxText="
                                            helpBoxTextEnum.ProjectStep1.Description.ShortName
                                        "
                                        ref="tagsInput"
                                        id="tagsInput"
                                        v-model="project.tags"
                                        type="text"
                                        place-holder="Acrescente tags a este projeto"
                                        textLabel="Tags"
                                        helpLabel="Ex: #pintura #doação"
                                        invalidInputMessage="Preencha com ao menos uma tag"
                                        @validationStatusChanged="validateStep"
                                        @input="checkForm()"
                                        :required="true"
                                    />
                                </v-flex>
                                <v-flex xs12>
                                    <InputSelect
                                        @focus="updateHelpBox"
                                        ref="targetAudienceInput"
                                        :helpBoxText="helpBoxTextEnum.ProjectStep1.Description.PublicTarget"
                                        v-model="project.targetAudienceId"
                                        :items="targetAudienceList"
                                        textLabel="Público"
                                        helpLabel="Ex: Idosos"
                                        valueAttribute="id"
                                        textAttribute="name"
                                        :required="false"
                                    ></InputSelect>
                                </v-flex>
                                <div v-if="project.projectTypeId == projectTypes.Ticket">
                                    <v-flex xs12>
                                        <InputText
                                            v-model.number="project.maxItemQuantityPerCPF"
                                            type="tel"
                                            textLabel="Limite de compra por CPF"
                                            helpLabel="Ex: 2"
                                            place-holder="Número máximo de itens permitidos por compra"
                                            mask="#########"
                                        />
                                    </v-flex>
                                    <v-flex xs12>
                                        <InputMoney
                                            ref="totalValueInput"
                                            v-model="project.totalValue"
                                            textLabel="Valor total do projeto"
                                            invalidInputMessage="Preencha o valor total do projeto"
                                            :precision="2"
                                            :required="false"
                                        />
                                    </v-flex>
                                </div>
                            </v-layout>
                        </div>

                        <BorderSeparation />

                        <div class="head">
                            <h4>Configurações para compartilhamento</h4>
                        </div>

                        <v-layout row wrap mw-total>
                            <v-flex
                                class="upload-box-profile-father"
                                style="width: 200px !important; height: 160px"
                                shrink
                                pr-3
                            >
                                <div
                                    class="upload-image bg-contain"
                                    style="width: 200px; min-height: 160px"
                                    id="share"
                                    ref="share"
                                >
                                    <label for="upload-campaign-share">
                                        <i class="fas fa-camera-alt"></i>
                                    </label>
                                    <label
                                        for="upload-campaign_share"
                                        class="do-upload"
                                        v-show="
                                            project.faviconFile.path == '' ||
                                            project.faviconFile.path == null
                                        "
                                    >
                                        <i class="fal fa-cloud-upload"></i>Ícone
                                    </label>
                                    <input
                                        type="file"
                                        id="upload-campaign_share"
                                        accept="image/*"
                                        @change="changeFavicon($event); checkForm()"
                                        ref="campaign_upload_share"
                                        required
                                    />
                                </div>
                            </v-flex>

                            <v-flex md8>
                                <v-layout row wrap>
                                    <v-flex xs12>
                                        <InputText
                                            ref="metaTitleInput"
                                            v-model="project.metaTitle"
                                            type="text"
                                            id="metaTitle"
                                            place-holder="Título"
                                            textLabel="Título"
                                            labelCharLimit="60"
                                            @input="checkForm"
                                            :required="true"
                                        />
                                    </v-flex>
                                    <v-flex xs12>
                                        <InputTextArea
                                            ref="metaDescriptionInput"
                                            v-model="project.metaDescription"
                                            id="metaDescription"
                                            rows="3"
                                            place-holder="Descrição"
                                            textLabel="Descrição"
                                            labelAreaCharLimit="160"
                                            @input="checkForm"
                                            :required="true"
                                        />
                                    </v-flex>
                                </v-layout>

                                <hr class="sm my-3" />
                                <v-layout row wrap>
                                    <v-flex f-size-14 caption text-gray-dark mt-1 xs12>
                                        O upload da imagem de compartilhamento e favicon deve ter
                                        até 500kb e ficará como exibido abaixo.
                                    </v-flex>
                                    <v-flex
                                        caption
                                        text-gray-dark
                                        mt-1
                                        xs12
                                    >Preview fav-icon: Google Chrome</v-flex>
                                    <v-flex mt-2>
                                        <div class="browser-bg light">
                                            <span
                                                class="fav-icon-preview"
                                                :data-text="project.metaTitle"
                                                id="favIconPreview1"
                                                ref="favIconPreview1"
                                            ></span>
                                        </div>
                                    </v-flex>
                                    <v-flex mt-2>
                                        <div class="browser-bg dark">
                                            <span
                                                class="fav-icon-preview"
                                                :data-text="project.metaTitle"
                                                id="favIconPreview2"
                                                ref="favIconPreview2"
                                            ></span>
                                        </div>
                                    </v-flex>
                                </v-layout>

                                <v-flex
                                    caption
                                    text-gray-dark
                                    mt-1
                                    xs12
                                >Preview Compartilhamento: Whatsapp</v-flex>
                                <PreviewShare
                                    :title="project.metaTitle"
                                    :description="project.metaDescription"
                                    :image-path="project.faviconFile.path || ''"
                                    :subdomain="project.subdomain"
                                ></PreviewShare>
                            </v-flex>
                        </v-layout>
                    </div>
                </div>
            </div>

            <div class="box-help-spacer"></div>
            <HelpBox :text="helpBoxText" />
        </v-layout>
        <div class="footer-step" style="display: flex;">
                <v-btn
                flat
                round
                :disabled="true"
                color="white"
                class="btn-default flex-inherit px-5"
                large
                v-model="pageOptions"
                >Voltar</v-btn>
                <v-btn
                    flat
                    round
                    color="white"
                    class="btn-default flex-inherit"
                    large
                    @click="backToList"
                >Voltar para Lista</v-btn>

            <div class="btn-right">
                <v-btn
                    flat
                    round
                    color="white"
                    class="btn-primary flex-inherit px-5"
                    large
                    v-model="pageOptions"
                    @click="nextPage"
                >Próximo</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import FooterStep from "@/components/FooterStep.vue";
import Volunteering from "@/scripts/models/volunteering.model";
import ProjectService from "@/scripts/services/project.service.js";
import VolunteeringService from "@/scripts/services/volunteering.service.js";
import VolunteeringAttributeOfferService from "@/scripts/services/volunteeringAttributeOffer.service";
import InstitutionService from "@/scripts/services/institution.service";
import { GetAllRequiredFields, CheckForm } from "@/scripts/helpers/fields-checker.js"
import { IncentiveLaws } from "@/scripts/models/enums/incentiveLaws.enum.js";
import {
    ProjectTypes,
    GetProjectTypeName,
} from "@/scripts/models/enums/projectTypes.enum.js";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import HelpBox from "@/components/HelpBox.vue";
import { HelpBoxTextVolunteering } from "@/scripts/models/enums/helpBoxTextVolunteering.enum.js";
import { mapGetters, mapActions } from "vuex";

import FileService from "@/scripts/services/file.service.js";
import File from "@/scripts/models/file.model.js";
import PreviewShare from "@/components/PreviewShare";
import { parse } from "date-fns";
import CommonHelper from "../../../scripts/helpers/common.helper";

export default {
    extends: ValidatableComponent,
    components: {
        PreviewShare,
        HelpBox,
        FooterStep,
    },
    props: ["pageOptions"],
    data() {
        return {
            project: new Volunteering(),
            volunteeringService: new VolunteeringService(),
            volunteeringAttributeOfferService: new VolunteeringAttributeOfferService(),
            projectService: new ProjectService(),
            fileService: new FileService(),
            institutionService: new InstitutionService(),
            routes: Routes,
            targetAudienceList: [],
            incentiveLaws: IncentiveLaws,
            helpBoxText: HelpBoxTextVolunteering.ProjectStep1.FullName,
            helpBoxTextEnum: HelpBoxTextVolunteering,
            projectTypes: ProjectTypes,
            breadcrumbLevels: [
                { text: "Início" },
                { text: "Projeto de Voluntariado" },
                { text: "Passo 1" },
            ],
            requiredFields: [],
            formIsOk: false,
        };
    },
    async created() {
        if (!this.$route.query.institutionId && !this.$route.query.volunteeringId) {
            CommonHelper.swal(
                "Instituição não informada",
                "Um projeto de voluntariado deve estar relacionado a uma instituição"
            );
            this.$router.go(-1);
        }
        this.listAllTargetAudience();
        this.$emit("changeLevels", this.breadcrumbLevels);
        this.pageOptions.currentPage = 1;
        await this.checkProject();
    },
    async mounted() {
        this.$refs.fullNameInput.focus();
        this.requiredFields = await GetAllRequiredFields()
        await this.checkForm()
    },
    methods: {
        nextPage() {
            this.volunteeringService.save(this.project).then((data) => {
                let query = this.$route.query;
                query.volunteeringId = data.id;
                this.$router.push({
                    name: this.routes.app.VolunteeringStep2,
                    query: query,
                });
            });
        },
        backToList() {
            if (this.$route.query.institutionId && this.$route.query.entityId) {
                this.$router.push({
                    name: this.routes.app.VolunteeringList,
                    query: {
                        institutionId: this.$route.institutionId,
                        entityId: this.$route.query.entityId
                    },
                });
                return;
            }

            this.institutionService.findById(this.project.institutionId).then(data => {
                this.$router.push({
                    name: this.routes.app.VolunteeringList,
                    query: {
                        institutionId: this.project.institutionId,
                        entityId: data.entity.id
                    },
                });
            });
        },
        updateHelpBox(text) {
            this.helpBoxText = text;
        },
        async checkProject() {
            new Promise(async (resolve) => {
                if (!this.$route.query.volunteeringId || this.$route.query.volunteeringId === 0 || this.$route.query.volunteeringId === null) {
                    this.project = new Volunteering(); 
                    this.project.institutionId = this.$route.query.institutionId;
                    return resolve(this.project)
                }

                    this.project = await this.loadProject(this.$route.query.volunteeringId);
                    return resolve(this.project)
            })   
        },
        async loadProject(id) {
            new Promise(async (resolve) => {
                await this.volunteeringService.findById(id).then(async (data) => {
                    this.project = data;
                    if (data.faviconFile == null) this.project.faviconFile = new File();
                    else {
                        this.renderShareCallback(data.faviconFile.path);
                    }

                    await this.volunteeringAttributeOfferService
                        .findByVolunteeringId(id)
                        .then(async (volunteeringAttributeOffers) => {
                            this.project.volunteeringAttributeOffers = volunteeringAttributeOffers;    
                            this.formIsOk = await CheckForm(this.requiredFields)                    
                        });
                    resolve(this.project)
                });
            })
        },
        listAllTargetAudience() {
            this.projectService.listAllTargetAudience(
                this.listAllTargetAudienceCallback
            );
        },
        listAllTargetAudienceCallback(data) {
            data = data.filter(
                (targetAudience) =>
                    !targetAudience.name
                        .toLowerCase()
                        .includes("adolecentes (acima de 11 anos)") &&
                    !targetAudience.name
                        .toLowerCase()
                        .includes("crianças (abaixo de 10 anos)") &&
                    !targetAudience.name
                        .toLowerCase()
                        .includes("idosos (acima de 60 anos)") &&
                    !targetAudience.name.toLowerCase().includes("geral") &&
                    !targetAudience.name.toLowerCase().includes("somente funcionários")
            );
            this.targetAudienceList = data;
        },
        showFailMessage(invalidElements) {
            if (
                invalidElements.length > 1 ||
                (invalidElements.length == 1 && invalidElements[0] != "odsListInput")
            ) {
                this.$store.commit(
                    "SET_SNACKBAR_MESSAGE",
                    "Você não preencheu todos os campos"
                );
            } else {
                this.$store.commit(
                    "SET_SNACKBAR_MESSAGE",
                    "Escolha ao menos um Objetivo de Desenvolvimento Sustentável"
                );
            }
        },
        validateStep() {
            let valid = this.isValid(false);
        },
        renderShareCallback(data) {
            const path = data.replace(
                "image/upload",
                "image/upload/w_150,h_150,c_pad,b_auto:predominant/"
            );
            this.$refs.share.style.backgroundImage = "url(" + data + ")";
            this.$refs.favIconPreview1.style.backgroundImage = "url(" + path + ")";
            this.$refs.favIconPreview1.style.backgroundSize = "contain";
            this.$refs.favIconPreview2.style.backgroundImage = "url(" + path + ")";
            this.$refs.favIconPreview2.style.backgroundSize = "contain";
        },
        async faviconFileChanged(event) {
            return new Promise((resolve) => {
                var file = event.target.files[0];
                //tamanho maximo da imagem de 500Kb
                if (file.size > 500 * 1024) {
                    this.$store.commit(
                        "SET_SNACKBAR_MESSAGE",
                        "A imagem deve ter no máximo 500Kb de tamanho"
                    );
                } else {
                    this.project.faviconFile.name = file.name;
                    let reader = new FileReader();
                    reader.onload = function () {
                        this.$refs.share.style.backgroundImage = "url(" + reader.result + ")";
                        this.project.faviconFile.path = reader.result;
                        resolve(true)
                    }.bind(this);
                    reader.readAsDataURL(file);
                }
            })
        },
        async changeFavicon(event) {
            await this.faviconFileChanged(event)
            await this.checkForm()
        },
        async checkForm() {
            const turnOnFormCheck = false // Turn on or Turn off checkform on "Próximo" button
            this.formIsOk = turnOnFormCheck === false ? true : await CheckForm(this.requiredFields)
        },
        async checkForm() {
            const turnOnFormCheck = false // Turn on or Turn off checkform on "Próximo" button
            this.formIsOk = turnOnFormCheck === false ? true : await CheckForm(this.requiredFields)
        }
    },
};
</script>
